<template>
  <div id="app">
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
/* @import '~bootstrap/dist/css/bootstrap.css'; */
@import "https://fonts.googleapis.com/css2?family=Pacifico&display=swap";
.material-icons {
  vertical-align: middle;
}
</style>

<script>
import Vue from "vue";
import { firestorePlugin } from "vuefire";
Vue.use(firestorePlugin);

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

Date.prototype.toJSONLocal = (function () {
  function addZ(n) {
    return (n < 10 ? "0" : "") + n;
  }
  return function () {
    return (
      this.getFullYear() +
      "-" +
      addZ(this.getMonth() + 1) +
      "-" +
      addZ(this.getDate())
    );
  };
})();

window.isMobile = (function () {
  return function () {
    // return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    return window.innerWidth <= 700;
  };
})();

window.getDisplayDateFromTimestamp = function (timestamp, showTime) {
  if (parseInt(timestamp)) {
    var date = new Date(parseInt(timestamp));
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    var displayDate = day + "/" + month + "/" + year;
    // var displayDate = date.toDateString();

    if (showTime == true) {
      displayDate =
        displayDate +
        " " +
        date.toLocaleString("en-US", {
          timeZone: 'Asia/Kolkata',
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
    }

    return displayDate;
  } else return "-";
};

export default {
  created() {
    // if (this.$workbox) {
    // 	this.$workbox.addEventListener("waiting", () => {
    // 		this.showUpdateUI = true;
    // 	});
    // }
  },
};
</script>
